<script>
import NativePerfArticleNew from './NativePerfArticleNew'
import NativeArticle from '../../model/DonNativePerfArticle'

export default {
  name: 'NativePerfArticleDuplicate',
  extends: NativePerfArticleNew,
  methods: {
    getCopy () {
      this.$store.dispatch('nativePerfArticle/getCopy', this.$route.params.id)
        .then(() => {
          const copy = this.$store.getters['nativePerfArticle/detail']

          this.item = this._.cloneDeep(NativeArticle)
          this.item.title = copy.title
          this.item.url = copy.url
          this.item.agency = copy.agency
          this.item.client = copy.client
          this.item.damImageId = copy.damImageId
          this.item.duplicate = true

          if (this.item.alternativesEnabled) {
            this.loadArticleAlternatives()
          } else {
            this.fetchMainImage(this.item.damImageId)
          }

          if (this.item.schedulingEnabled) {
            this.setSchedulingConfig()
          }
        })
    }
  },

  created () {
    this.dataLoaded = false
    this.getCopy()
  }
}
</script>
